import React from "react";
import { Layout, Typography, Space, Row, Col } from "antd";
import { Link } from 'react-router-dom';


const { Header } = Layout;
const { Title, Text } = Typography;

export const Navbar = () => {
    return (
        <Header style={{ background: "#fff", padding: "0" }}>
            <Row justify="center" style={{ maxWidth: "1200px", margin: "0 auto", width: "100%" }}>
                <Col xs={24} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 24px" }}>
                    <Title level={3} style={{ margin: 0 }}>
                        Monkey Pay
                    </Title>
                    <Space size="large">
                        <Text strong>Individuals</Text>
                        <Text strong>Business</Text>
                        <Text strong>Buy</Text>
                        <Text strong>Sell</Text>
                        <Text strong>Swap</Text>
                    </Space>
                </Col>
            </Row>
        </Header>
    )
}