import React, { useState } from "react";
import { Layout, Typography, Button, Card, Select, Space, Row, Col, Divider } from "antd";
import { Navbar } from "./components/navbar";

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

const Homepage = () => {
    const [amount, setAmount] = useState(300);
    const [crypto, setCrypto] = useState("Bitcoin");

    // External icon links
    const trustedIcons = [
        { src: "https://payload-marketing.moonpay.com/api/media/file/metamask@2x.png", alt: "Metamask" },
        { src: "https://payload-marketing.moonpay.com/api/media/file/paypal-2.png", alt: "PayPal" },
        { src: "https://payload-marketing.moonpay.com/api/media/file/trust@2x.png", alt: "Trust Wallet" },
        { src: "https://payload-marketing.moonpay.com/api/media/file/universal@2x.png", alt: "Universal" },
        { src: "https://payload-marketing.moonpay.com/api/media/file/mastercard.png", alt: "Mastercard" },
        { src: "https://payload-marketing.moonpay.com/api/media/file/uniswap@2x.png", alt: "Uniswap" },
    ];

    return (
        <Layout style={{ minHeight: "100vh", background: "#f9f9f9" }}>
            {/* Header */}
            <Navbar  />

            {/* Main Content */}
            <Content style={{ padding: "50px 24px" }}>
                <Row justify="center" align="middle" gutter={[32, 32]} style={{ maxWidth: "1200px", margin: "0 auto" }}>
                    {/* Left Section */}
                    <Col xs={24} md={12} style={{ textAlign: "center", maxWidth: "500px" }}>
                        <Title level={2}>
                            Want to buy crypto? With MonkeyPay, it's never been simpler.
                        </Title>
                        <Text>
                            100+ cryptocurrencies. Plenty of ways to pay. Absolutely zero hassle. MonkeyPay makes
                            it easy to buy, sell, and swap crypto. Open your free account today.
                        </Text>
                        <br />
                        <Button type="primary" size="large" style={{ marginTop: "20px" }}>
                            Get Started
                        </Button>
                        <div style={{ marginTop: "10px" }}>
                            <Text type="secondary">TrustScore 4.2</Text> <br />
                            <Text type="secondary">93K+ Reviews</Text>
                        </div>
                    </Col>

                    {/* Right Section */}
                    <Col xs={24} md={12} style={{ display: "flex", justifyContent: "center" }}>
                        <Card style={{ width: "100%", maxWidth: "350px", textAlign: "center" }}>
                            <Title level={4}>Buy</Title>
                            <div style={{ marginBottom: "20px" }}>
                                <Title level={2} style={{ margin: 0 }}>${amount}</Title>
                                <Text type="secondary">{(amount / 110000).toFixed(5)} BTC</Text>
                            </div>

                            {/* Amount Buttons */}
                            <Space size="middle" style={{ marginBottom: "20px" }}>
                                {[100, 250, 500, 1000].map((value) => (
                                    <Button
                                        key={value}
                                        type={amount === value ? "primary" : "default"}
                                        onClick={() => setAmount(value)}
                                    >
                                        ${value}
                                    </Button>
                                ))}
                            </Space>

                            {/* Crypto Selector */}
                            <div style={{ marginBottom: "20px" }}>
                                <Text strong>Buy</Text>
                                <Select
                                    value={crypto}
                                    onChange={(value) => setCrypto(value)}
                                    style={{ width: "100%", marginTop: "10px" }}
                                >
                                    <Option value="Bitcoin">Bitcoin</Option>
                                    <Option value="Ethereum">Ethereum</Option>
                                    <Option value="Litecoin">Litecoin</Option>
                                </Select>
                            </div>

                            {/* Continue Button */}
                            <Button type="primary" block>
                                Continue
                            </Button>
                        </Card>
                    </Col>
                </Row>

                {/* Divider */}
                <Divider style={{ margin: "50px 0" }} />

                {/* Trusted Section */}
                <Row justify="center" style={{ marginTop: "50px", textAlign: "center", maxWidth: "1200px", margin: "0 auto" }}>
                    <Col xs={24} style={{ marginBottom: "20px" }}>
                        <Text type="secondary">Trusted by</Text>
                    </Col>
                    <Col xs={24} style={{ marginBottom: "30px" }}>
                        <Space size="large" wrap>
                            {/* Render icons dynamically */}
                            {trustedIcons.map((icon, index) => (
                                <img
                                    key={index}
                                    src={icon.src}
                                    alt={icon.alt}
                                    style={{ width: "100px", height: "auto", objectFit: "contain" }}
                                />
                            ))}
                        </Space>
                    </Col>
                    <Col xs={24}>
                        <Title level={2}>Buy, sell, and swap seamlessly.</Title>
                        <Title level={4}>
                            Pay for education and medical services with ease. MonkeyPay brings a new way to manage
                            your digital payments.
                        </Title>
                        <Button type="primary" size="large" style={{ marginTop: "20px" }}>
                            Buy crypto
                        </Button>
                    </Col>
                </Row>

                {/* Divider */}
                <Divider style={{ margin: "50px 0" }} />
            </Content>

            {/* Footer */}
            <Footer style={{ textAlign: "center" }}>
                © 2025 MonkeyPay. All rights reserved.
            </Footer>
        </Layout>
    );
};

export default Homepage;
