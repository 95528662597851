import React from "react";
import { Layout, Typography, Form, Input, Button, Row, Col } from "antd";
import { Link } from 'react-router-dom';

const { Content, Footer } = Layout;
const { Title, Text } = Typography;

const LoginPage = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout style={{ minHeight: "100vh", background: "#f9f9f9" }}>
      <Content style={{ padding: "50px 24px" }}>
        <Row justify="center" style={{ margin: "0 auto", maxWidth: "400px" }}>
          <Col xs={24}>
            <Title level={2} style={{ textAlign: "center" }}>Login to MonkeyPay</Title>
            <Text type="secondary" style={{ textAlign: "center", display: "block", marginBottom: "20px" }}>
              Securely manage your crypto and personal finance.
            </Text>
            <Form
              name="login"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "Please input your password!" }]}
              >
                <Input.Password placeholder="Enter your password" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" block>
                  <Link to="/panel">Login</Link>
                </Button>
              </Form.Item>
            </Form>
            <Text>
              Don’t have an account? <a href="/signup">Sign up</a>
            </Text>
          </Col>
        </Row>
      </Content>

      <Footer style={{ textAlign: "center" }}>
        © 2025 MonkeyPay. All rights reserved.
      </Footer>
    </Layout>
  );
};

export default LoginPage;
