// App.jsx
import React from "react";
import { Layout } from "antd";
import { Navbar } from "./components/userNavbar";
import Sidebar from "./components/userSidebar";
import ContentArea from "./components/userContentArea";
import "./components/user.css"

const { Header, Sider, Content } = Layout;

const PersonalPanel = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ padding: "0" }}>
        <Navbar />
      </Header>
      <Content
        style={{
          padding: "24px",
          // background: "#f0f2f5",
          margin: "0 auto",
          maxWidth: "1200px",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", gap: "16px" }}>
          {/* Sidebar */}
          <div style={{ width: "250px", padding: "16px", borderRadius: "8px", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)" }}>
            <Sidebar />
          </div>

          {/* Main Content */}
          <div style={{ flex: 1, padding: "16px", borderRadius: "8px", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)" }}>
            <ContentArea />
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default PersonalPanel;
