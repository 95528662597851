import React from "react";
import { Menu } from "antd";
import { PieChartOutlined, FundOutlined, StarOutlined } from "@ant-design/icons";

const Sidebar = () => {
  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={["1"]}
      style={{ height: "100%", borderRight: 0 }}
    >
      <Menu.Item key="2" icon={<StarOutlined />}>
        Cryptos to Watch
      </Menu.Item>
      <Menu.Item key="3" icon={<FundOutlined />}>
        My First List
      </Menu.Item>
    </Menu>
  );
};

export default Sidebar;
