import React from "react";
import { Typography, Row, Col, Card, Table, Statistic, Divider } from "antd";

const { Title, Paragraph } = Typography;

const transactionData = [
  {
    key: "1",
    date: "2025-01-18",
    description: "Tuition payment - University of XYZ",
    amount: "0.045 BTC",
    status: "Completed",
  },
  {
    key: "2",
    date: "2025-01-15",
    description: "Medical bill payment - General Hospital",
    amount: "0.1 ETH",
    status: "Completed",
  },
  {
    key: "3",
    date: "2025-01-10",
    description: "Book purchase - CryptoLearning Inc.",
    amount: "50 USDT",
    status: "Pending",
  },
  {
    key: "4",
    date: "2025-01-05",
    description: "Medical consultation fee",
    amount: "0.02 BTC",
    status: "Completed",
  },
];

const propData = [
  {
    title: "Crypto for Education",
    description:
      "Enable seamless payments for tuition and educational materials using secure cryptocurrency transactions.",
  },
  {
    title: "Crypto for Medical Care",
    description:
      "Facilitate affordable and fast payments for medical bills and services with crypto payment solutions.",
  },
  {
    title: "Secure Transactions",
    description:
      "Use blockchain technology to ensure safe and transparent payments for critical services.",
  },
  {
    title: "Global Accessibility",
    description:
      "Allow international students and patients to make cross-border payments effortlessly with cryptocurrency.",
  },
];

const portfolioStats = {
  totalValue: "2.34 BTC",
  totalTransactions: 45,
  successfulTransactions: 42,
  pendingTransactions: 3,
};

const ContentArea = () => {
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <div>
      <Title level={2}>Welcome User!</Title>
      <Paragraph>
        Explore how cryptocurrency enables secure, fast, and transparent
        payments for education and medical care. Check your portfolio and
        transaction history below.
      </Paragraph>

      <Row gutter={[16, 16]}>
        {propData.map((item, index) => (
          <Col xs={24} sm={12} md={8} lg={6} key={index}>
            <Card title={item.title} bordered hoverable>
              {item.description}
            </Card>
          </Col>
        ))}
      </Row>

      {/* Divider */}
      <Divider style={{ margin: "50px 0" }} />

      <Title level={2}>Profile</Title>

      {/* Portfolio Summary */}
      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Portfolio Value"
              value={portfolioStats.totalValue}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Transactions"
              value={portfolioStats.totalTransactions}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Successful Transactions"
              value={portfolioStats.successfulTransactions}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Pending Transactions"
              value={portfolioStats.pendingTransactions}
            />
          </Card>
        </Col>
      </Row>

      {/* Recent Transactions */}
      <Title level={3}>Recent Transactions</Title>
      <Table
        dataSource={transactionData}
        columns={columns}
        pagination={{ pageSize: 5 }}
      />
    </div>
  );
};

export default ContentArea;
